import { useFormikContext } from 'formik';
import capitalize from '~/utils/string';

interface FormErrorProps {
  name: string;
}

const FormError = ({ name }: FormErrorProps) => {
  const {
    touched,
    errors,
  }: {
    touched: { [key: string]: any };
    errors: { [key: string]: any };
  } = useFormikContext();

  const isTouched = touched?.[name];
  const error = errors?.[name];
  const shouldShowError = isTouched && error;

  if (!shouldShowError) return null;

  return <span className="c-error mt-1">{capitalize(error || '')}</span>;
};

export default FormError;
