import { ReactNode } from 'react';
import Logo from '~/components/common/logo.component';

interface BaseAuthPageProps {
  title: string;
  children: ReactNode;
}

const BaseAuthPage = ({ title, children }: BaseAuthPageProps) => {
  return (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo dark />
        <h2 className="mt-6 mb-8 text-center">{title}</h2>
        <div className="c-card">{children}</div>
      </div>
    </div>
  );
};

export default BaseAuthPage;
