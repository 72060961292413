import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AuthenticationService, {
  AUTH_STATUS_ERROR,
  AUTH_STATUS_NEW_PASSWORD_REQUIRED,
  AUTH_STATUS_OK,
} from '@auth/services/authentication.service';
import useAuth from '@auth/hooks/use-auth.hook';
import BaseAuthPage from '@auth/components/base-auth-page.component';
import FormError from '@auth/components/form-error.component';
import capitalize from '~/utils/string';

type LoginError = {
  code: string;
  message: string;
};

const Login = () => {
  const {
    handleForcePasswordChange,
    handleLoggedIn,
    handlePasswordResetRequest,
  } = useAuth();
  const [error, setError] = useState<LoginError | null>(null);
  const { t } = useTranslation('translations');
  const schema = Yup.object().shape({
    password: Yup.string().required(t('verplicht veld')),
    email: Yup.string()
      .email(t('ongeldig e-mailadres'))
      .required(t('verplicht veld')),
  });

  return (
    <BaseAuthPage title={t('log in op INNIsure')}>
      {error && (
        <span className="mb-4 c-error">
          {error?.message
            ? capitalize(t(error.message))
            : capitalize(t('login mislukt'))}
        </span>
      )}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={async ({ email, password }, { setSubmitting }) => {
          setError(null);

          const {
            status,
            user,
            userAttributes,
            session,
            ...response
          } = await AuthenticationService.login({
            email: email.toLowerCase(),
            password,
          });

          if (status === AUTH_STATUS_NEW_PASSWORD_REQUIRED && user) {
            handleForcePasswordChange?.({
              user,
              userAttributes,
            });
          } else if (
            'code' in response &&
            response.code === 'UserNotFoundException'
          ) {
            setError({
              code: response.code,
              message: 'Deze gebruiker bestaat niet',
            });
          } else if (
            'code' in response &&
            response.code === 'NotAuthorizedException'
          ) {
            setError({
              code: response.code,
              message: 'ongeldige inloggegevens',
            });
          } else if (status === AUTH_STATUS_ERROR) {
            setError(JSON.parse(response.error) || null);
          } else if (status === AUTH_STATUS_OK && user && session) {
            handleLoggedIn?.({ user, userAttributes, session });
          }
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">
                {capitalize(t('e-mailadres'))}
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="c-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </label>
              <FormError name="email" />
            </div>
            <div>
              <label htmlFor="password">
                {capitalize(t('wachtwoord'))}
                <input
                  id="password"
                  className="c-input"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </label>
              <FormError name="password" />
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="c-button--primary"
                disabled={isSubmitting}
              >
                {capitalize(t('log in'))}
              </button>
              <button
                className="c-button--link"
                onClick={() => handlePasswordResetRequest?.()}
                type="button"
              >
                {capitalize(t('wachtwoord vergeten?'))}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </BaseAuthPage>
  );
};

export default Login;
