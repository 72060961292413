import LogInContainer from '@auth/containers/login.container';
import { Page } from '~/types';

const LogIn: Page = () => {
  return <LogInContainer />;
};

LogIn.isPublic = true;

export default LogIn;
